.inspiration {
  .inspiration__promotion {
    aspect-ratio: 5 / 4;

    @media screen and (min-width: @screen-lg) {
      max-width: 248px;
    }

    @media screen and (min-width: @screen-xl) {
      max-width: 280px;
    }

    a {
      position: relative;
      height: 100%;

      .tintOverlay {
        background: rgba(black, 0.2);
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        p {
          position: absolute;
          bottom: 0;
          padding: @spacer5;
          color: white;
          margin: 0;
          text-shadow: 0px 0px 50px rgba(black, 0.6);
        }
      }
      img {
        object-fit: cover;
        height: 100%;
        width: 100%;
      }
    }
  }
}

.menu-wrapper.mobile .mainNavigation .inspiration {
  padding: @spacer6 @spacer6 0 @spacer6;

  .inspiration__header {
    border-top: 0.1rem solid @Gray20;
    padding-top: @spacer9;
  }
  .inpiration__links {
    margin-bottom: @spacer8;
  }
}

.menu-wrapper.desktop .menuContent .menuContent__block .inspiration {
  width: 30%;
  padding: 0 0 0 @spacer9;
  border-left: 0.1rem solid @Gray20;
  @media screen and (min-width: @screen-xl) {
    padding: 0 0 0 @spacer11;
  }
  .inpiration__links {
    margin-bottom: @spacer9;
  }
}
