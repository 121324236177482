@ClFontPath: './fonts';

@font-face {
  font-family: 'cl-icons';
  src:
    url('@{ClFontPath}/cl-icons.ttf') format('truetype'),
    url('@{ClFontPath}/cl-icons.woff') format('woff'),
    url('@{ClFontPath}/cl-icons.svg#cl-icons') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='cl-icon-'],
[class*=' cl-icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'cl-icons' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  display: inline-block;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.cl-icon-logo40-grey:before {
  content: '\e800';
  color: #575757;
}
.cl-icon-logo40-white:before {
  content: '\e800';
  color: #fff;
}
