@import '/Foundation/Styling/code/thule/global/media-queries.less';
@import '@{variablesLocation}';
@import '/Foundation/Styling/code/thule/global/frg-icons.less';
@import '/Project/CaseLogic/code/CaseLogic/icons/cl-icons.less';
@import 'Inspiration.less';

#thule-header {
  #REACT_mainnavigation {
    height: 7rem;
    width: 100%;
    position: relative;

    @media screen and (min-width: @screen-lg) {
      height: 8.2rem;
    }

    &:has(.menu-wrapper.desktop.is-checkout) {
      display: none;
    }

    &.transparent {
      .menu-wrapper:not(.menu--open) {
        background-color: transparent;

        &.mobile {
          .menu__content {
            border-bottom-color: transparent;
          }

          .mainNavigation {
            background-color: white;
          }
        }

        &.desktop {
          &:not(.menu--open) {
            border-bottom-color: transparent;
          }

          .menuPrimary {
            background-color: transparent;
          }

          .menuItem__title {
            color: white;
          }

          .mainNavigation {
            background-color: transparent;
          }
        }

        .logotype-link {
          color: white;
        }

        .logotype-cl::before {
          color: @White;
        }

        .quickLinks i {
          background-color: white;
        }

        &.expanded {
          transition:
            0.4s transform ease,
            background-color 0.5s;
          background-color: transparent;
        }
      }
    }

    .menu-wrapper.idle {
      transform: translateY(-100%);
    }

    .menu-wrapper.collapsed {
      transition: 0.4s transform ease;
      transform: translateY(-100%);
      overflow: hidden;
      width: 100%;
      position: fixed;
      top: 0;
    }

    .menu-wrapper.expanded {
      transition: 0.4s transform ease;
      transform: translateY(0);
      position: fixed;
      top: 0;
    }
  }

  @menuTopHeight: 4rem;
  z-index: 1004;
  width: 100%;

  button > * {
    pointer-events: none;
  }

  .menu-wrapper {
    width: 100%;

    .inspiration {
      .inspiration__promotion {
        img {
          width: 100%;
        }
      }
    }

    .quickLinks {
      .quickLinks__btn {
        padding: 0;
        display: flex;
        align-items: center;

        &:not(:first-child) {
          padding-left: @spacer6;
        }

        @media screen and (min-width: @screen-lg) {
          &:not(:first-child) {
            padding-left: @spacer5;
          }
        }

        @media screen and (min-width: @screen-xl) {
          &:not(:first-child) {
            padding-left: @spacer7;
          }
        }

        &.cartLink {
          position: relative;

          .cart-counter {
            height: 2rem;
            width: 2rem;
            background-color: @Black;
            border-radius: 50%;
            position: absolute;
            left: 33px;
            top: -8px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 10px;
            color: white;
            z-index: 1;

            &:empty {
              display: none;
            }

            @media screen and (min-width: @screen-lg) {
              left: 29px;
            }

            @media screen and (min-width: @screen-xl) {
              left: 37px;
            }
          }
        }
      }
    }

    .search {
      position: absolute;
      top: 0;
      left: 0;
      height: 100vh;
      width: 100vw;
      background-color: white;
      z-index: 2;
      padding: 1rem @spacer6 0 @spacer6;

      @media screen and (max-width: @screen-md-max) {
        overflow-y: auto;
        position: fixed;
      }

      .search__border {
        @media screen and (min-width: @screen-lg) {
          border-top: 0.1rem solid @Gray20;
        }
      }

      .search__result--product {
        padding-bottom: @spacer11;

        @media screen and (min-width: @screen-lg) and (max-width: @screen-lg-max) {
          padding-left: @spacer7;
        }

        @media screen and (min-width: @screen-lg) {
          padding-right: @spacer7;
        }

        article {
          position: relative;

          @media screen and (min-width: @screen-lg) {
            width: calc(25% - 22px);

            &:not(:last-child) {
              margin-right: @spacer7;
            }
          }

          .extranet-search-product {
            .product__text {
              margin-left: 0 !important;
            }
          }

          .search__product {
            @media screen and (max-width: @screen-md-max) {
              margin-bottom: @spacer4;
            }

            @media screen and (min-width: @screen-lg) {
              flex-direction: column;
            }

            .product__text {
              margin-left: @spacer4;

              @media screen and (min-width: @screen-lg) {
                margin-left: 0;
              }
            }

            .product__imageContainer {
              @media screen and (max-width: @screen-xs-max) {
                min-width: 30%;
              }

              @media screen and (max-width: @screen-md-max) {
                width: 30%;
                max-width: 180px;
              }

              img {
                width: 100%;
                aspect-ratio: 1 / 1;
                object-fit: contain;
              }
            }

            .add-to-cart-button-react {
              flex-direction: column;

              .quantity-price-container,
              .price-container,
              .btn-container {
                margin: 0;
              }

              .quantity-price-container {
                margin-bottom: @spacer3;
              }

              .price {
                margin-bottom: @spacer3;
              }

              .rrp {
                margin-bottom: 0;
              }

              .btn-container {
                align-items: flex-start;

                button {
                  margin-bottom: @spacer1;
                }

                .stock-status {
                  margin-bottom: @spacer2;
                }
              }

              .big-spinner {
                font-size: 2.5rem;
              }
            }
          }
        }
      }

      @media screen and (min-width: @screen-lg) {
        padding: 0;
        min-height: 1rem;
        height: unset;

        .searchOverlay::after {
          content: '';
          background-color: @PitchBlack;
          padding-left: 100vw;
          padding-bottom: 100vh;
          opacity: 0.5;
          position: absolute;
        }
      }

      form {
        flex: 1;
      }

      .searchInputContainer__wrapper {
        height: 7rem;

        @media screen and (min-width: @screen-lg) {
          height: 8.2rem;
        }

        .searchInputContainer {
          border-bottom: 0.1rem @Gray60 solid;
          align-items: center;
          padding: @spacer3;
          display: flex;
          width: 100%;

          @media screen and (max-width: @screen-md-max) {
            height: 4.8rem;
          }
        }
      }

      input {
        border: none;
        outline: none;
        background-color: transparent;

        &::placeholder {
          color: @Gray60;
          opacity: 1;
        }

        &:-ms-input-placeholder {
          color: @Gray60;
        }

        &::-ms-input-placeholder {
          color: @Gray60;
        }
      }
    }

    a {
      display: block;

      &.logotype-link {
        flex: 0;
        color: @Black;
        z-index: 2;

        @media screen and (min-width: @screen-lg) {
          z-index: 3;
          display: flex;
          align-items: center;
          margin-right: @spacer6;
        }

        i.logotype {
          font-size: @LogoTypeMobileSize;

          @media screen and (min-width: @screen-lg) {
            font-size: @LogoTypeDesktopSize;
          }

          &:extend([class^='frg-icon-'],
          [class*=' frg-icon-'] all);
          &:extend(.frg-icon-thule-logo-only-text all);
        }

        i.logotype-cl {
          font-size: @LogoTypeMobileSizeCL;

          @media screen and (min-width: @screen-lg) {
            font-size: @LogoTypeDesktopSizeCL;
          }

          &:extend([class^='cl-icon-'],
          [class*=' cl-icon-'] all);
          &:extend(.cl-icon-logo40-grey all);
        }
      }
    }
  }

  // MOBILE //////////////
  .menu-wrapper.mobile {
    background-color: white;

    &.menu--open {
      position: fixed;
      width: 100%;
      top: 0;
      height: 100vh;
      z-index: 1;

      .mainNavigation,
      .menuItem__mainContent.open {
        overflow: scroll;

        &::-webkit-scrollbar {
          display: none;
        }

        -ms-overflow-style: none;
        scrollbar-width: none;

        height: 100vh;
      }

      .mainNavigation {
        height: calc(var(--vh, 1vh) * 100 - 7rem);
      }

      .menuItem__mainContent.open {
        padding-top: @spacer7;
        padding-bottom: @spacer16;
        height: calc(
          var(--vh, 1vh) * 100 - 13.2rem
        ); //13.2 - need to calculate height of header 7rem + menuitem 6.2rem. Recalculate if design elements change in size
      }
    }

    .menu__content {
      display: flex;
      align-items: center;
      display: flex;
      justify-content: space-between;
      height: 7rem;
      border-bottom: 0.1rem solid @Gray10;
    }

    &.layout__content {
      min-height: unset;
    }

    .mainNavigation {
      height: 100vh;

      .mainNavigation__links {
        border-top: 0.1rem solid @Gray10;
        padding: @spacer6 0 @spacer16 0;
        margin: 0 @spacer6;

        li {
          display: flex;
          align-items: center;

          a,
          button {
            padding: @spacer5 0;
          }

          i[class*='le-icon-'] {
            background: @Gray80;
          }
        }
      }

      ul.menuPrimary {
        background-color: @White;
        padding: 0;

        li.menuPrimary__menuItem {
          list-style: none;
          padding: 0;

          button.menuItem__btn,
          a.menuItem__btn {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0;
            width: 100%;

            .fa-chevron-left,
            .fa-chevron-right {
              color: @Black;
            }

            * {
              pointer-events: none;
            }
          }

          button.btn--back {
            border: none;
            width: 100%;
          }
        }
      }
    }
  }

  // DESKTOP ///////////////
  .menu-wrapper.desktop {
    z-index: 1;
    background-color: white;

    &.menu--open::after {
      content: '';
      background-color: @PitchBlack;
      padding-left: 100%;
      padding-bottom: 100%;
      opacity: 0.5;
      position: absolute;
    }

    &:not(.menu--open) {
      border-bottom: 0.1rem @Gray20 solid;
    }

    .layout__content {
      min-height: unset;
      padding-top: 0;
    }

    .menuContent {
      background-color: white;
      border-top: 0.1rem @Gray20 solid;
      position: relative;
      z-index: 1003;

      .menuContent__filler {
        &::after {
          content: '';
          background-color: @Black;
          padding-left: 100vw;
          padding-bottom: 100vh;
          opacity: 0.5;
        }
      }

      .menuContent__block {
        min-height: 30vh;
        max-width: 1324px;
        display: flex;
        padding-top: @spacer9;
        padding-bottom: @spacer12;

        .heading5,
        .heading6 {
          margin-bottom: 2rem;
          display: block;
        }

        .menuSections.full {
          width: 100%;
          padding-right: 0;

          &.support {
            max-width: 928px;

            @media screen and (min-width: @screen-xl) {
              max-width: 1024px;
            }

            margin: 0 auto;

            .menuSection {
              flex-basis: calc(25%);
              padding-right: @spacer8;

              @media screen and (min-width: @screen-xl) {
                flex-basis: calc(25%);
                padding-right: @spacer12;
              }

              &:last-child {
                padding-right: 0;
              }
            }
          }

          .menuSection {
            flex-basis: 25%;
            width: unset;
            margin-right: 0;
          }

          &.showMore {
            display: flex;
            justify-content: center;

            .menuSection {
              flex-basis: calc(100% / 6 - 10rem / 6);
              margin-right: @spacer6;

              &:last-child {
                margin-right: 0;
              }
            }
          }
        }

        .menuSections {
          flex-wrap: wrap;
          width: 70%;
          display: flex;
          row-gap: @spacer10;

          &:not(.full) {
            .menuSection {
              padding-right: @spacer8;

              @media screen and (min-width: @screen-xl) {
                padding-right: @spacer12;
              }

              &:nth-child(3n) {
                padding-right: @spacer9;

                @media screen and (min-width: @screen-xl) {
                  padding-right: @spacer11;
                }
              }
            }
          }

          .menuSection {
            flex-basis: calc(100% / 3);

            .menuSection__categoryLink {
              color: @Black;
            }

            .menuSection__link {
              .menuSection__image {
                background-color: @Gray20;
                aspect-ratio: 4 / 5;

                img {
                  aspect-ratio: inherit;
                  object-fit: cover;
                }
              }
            }
          }
        }
      }
    }

    .menuPrimary {
      width: 100%;
      display: flex;
      height: 8.2rem;
      background-color: white;

      .mainNavigation {
        display: flex;
        align-items: center;
        width: 100%;

        ul.menuDesktop {
          height: 100%;
          display: flex;
          align-items: center;
          padding: 0;

          .menuDesktop__menuItem {
            list-style: none;
            height: 100%;
            display: flex;
            align-items: center;
            position: relative;

            &.link:hover {
              &::after {
                content: '';
                border-bottom: 3px solid @Black;
                width: 100%;
                position: absolute;
                bottom: 0;
              }
            }

            .menuItem__link {
              display: flex;
              align-items: center;
            }

            .menuItem__btn,
            .menuItem__link {
              width: 100%;
              height: 100%;

              &.active {
                border-bottom: 0.3rem @Black solid;

                span {
                  margin-top: 0.3rem;
                  display: block;
                }
              }
            }
          }
        }
      }
    }
  }
}

.thule-site:not(.caselogic-site) {
  #thule-header {
    .search__product {
      img {
        background-color: @Gray10;
      }
    }
  }
}
